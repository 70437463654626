// @flow
import React from 'react';
import { getContactHref } from '../../../utils';
import styles from './Author.module.scss';
import { useSiteMetadata } from '../../../hooks';

const Author = () => {
  const { author } = useSiteMetadata();

  return (
    <div className={styles['author']}>
	  {author.name}
      <p className={styles['author__bio']}>
		{author.bio}
		<br/>
        <a class="twitter-follow-button"
			href="https://twitter.com/cleancoderocker"
		>
			Follow @cleancoderocker
		</a>
      </p>
    </div>
  );
};

export default Author;
